<template>
  <div class="cart-item">
    <div class="lead-time-wrapper">
      <ProductLeadTime
        v-if="item.product.productInfos.LEAD_TIME"
        :time="item.product.productInfos.LEAD_TIME"
        :showTooltip="true"
        :isCheckout="isCheckout"
      />
    </div>
    <div class="day-lock-wrapper">
      <ProductDayLock
        v-if="item.product.productInfos.DAY_LOCK"
        :days="item.product.productInfos.DAY_LOCK"
        :showTooltip="true"
        :isCheckout="isCheckout"
      />
    </div>
    <div class="lock-cutoff-wrapper">
      <ProductLockCutoff
        v-if="item.product.productInfos.PRODUCT_LOCK_CUTOFF"
        :hours="item.product.productInfos.PRODUCT_LOCK_CUTOFF"
        :showTooltip="true"
        :isCheckout="isCheckout"
      />
    </div>
    <div class="lock-cutoff-wrapper">
      <ProductAxBCutoff
        v-if="item.product.productInfos.PRODUCT_AXB_CUTOFF"
        :hour="item.product.productInfos.PRODUCT_AXB_CUTOFF"
        :showTooltip="true"
        :isCheckout="isCheckout"
      />
    </div>
    <template
      v-if="deliveryServiceWithReplaceableItems && isReplaceable && isCheckout"
    >
      <v-icon
        :color="acceptAlternative ? 'primary' : '#ccc'"
        class="accept-alternative-icon"
      >
        $replace
      </v-icon>
    </template>
    <v-list-item
      :to="{
        name: 'Product',
        params: { slug: item.product.slug }
      }"
      class="cart-item-list-item"
      :class="{
        'ml-2':
          deliveryServiceWithReplaceableItems &&
          acceptAlternatives &&
          isCheckout
      }"
    >
      <v-list-item-avatar
        :width="full ? '80' : '60'"
        :height="full ? '80' : '60'"
        rounded="0"
      >
        <v-img :src="item.product.mediaURL" :alt="'Immagine prodotto'"></v-img>
      </v-list-item-avatar>

      <v-list-item-content class="cart-item-content">
        <!-- <div class="lead-time-wrapper">
          <ProductLeadTime
            v-if="item.product.productInfos.LEAD_TIME"
            :time="item.product.productInfos.LEAD_TIME"
            :showTooltip="true"
            :isCheckout="isCheckout"
          />
        </div>
        <div class="day-lock-wrapper">
          <ProductDayLock
            v-if="item.product.productInfos.DAY_LOCK"
            :days="item.product.productInfos.DAY_LOCK"
            :showTooltip="true"
            :isCheckout="isCheckout"
          />
        </div>
        <div class="lock-cutoff-wrapper">
          <ProductLockCutoff
            v-if="item.product.productInfos.PRODUCT_LOCK_CUTOFF"
            :hours="item.product.productInfos.PRODUCT_LOCK_CUTOFF"
            :showTooltip="true"
            :isCheckout="isCheckout"
          />
        </div> -->
        <ProductPromo
          v-if="item.product.warehousePromo && full"
          :warehousePromo="item.product.warehousePromo"
        ></ProductPromo>
        <v-list-item-subtitle class="text-uppercase">
          {{ item.product.shortDescr }}
        </v-list-item-subtitle>
        <v-list-item-title class="font-weight-bold">
          {{ item.product.name }}
        </v-list-item-title>

        <v-list-item-subtitle>
          <span
            class="weight-unit"
            v-if="
              item.product.productInfos.TIPOLOGIA == 'Pezzo' &&
                item.product.priceUmDisplay
            "
          >
            {{ $n(item.product.priceUmDisplay, "currency") }}/{{
              item.product.weightUnitDisplay
            }},
          </span>
          {{ item.product.description }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <div :class="'actions d-flex align-center'">
          <ProductPrice
            :product="item.product"
            :showStandard="false"
            v-if="full"
          ></ProductPrice>
          <ProductPromo
            :warehousePromo="item.product.warehousePromo"
            v-if="false"
          />
          <div
            v-if="
              !(
                item.product.productInfos &&
                item.product.productInfos.USER_DISABLED == '1'
              )
            "
            class="qty-wrap rounded-pill"
            :class="{ 'not-empty': quantity > 0 }"
          >
            <a
              role="button"
              class="minus rounded-circle"
              @click.prevent.stop="minus"
              @mousedown.stop
            >
              <v-icon aria-label="Aggiungere meno quantità">$minus</v-icon>
            </a>
            <div class="val-cnt">
              <span class="val">{{ quantity }} {{ unit }}</span>
              <span class="small">{{ quantityPerUnit }}</span>
            </div>
            <a
              aria-label="Aggiungi al tuo carrello"
              role="button"
              class="plus rounded-circle"
              @click.prevent.stop="plus"
              @mousedown.stop
            >
              <v-icon v-if="quantity > 0" aria-label="Aggiungere più quantità"
                >$plus</v-icon
              >
              <v-icon v-else aria-label="Aggiungi al tuo carrello"
                >$cart</v-icon
              >
            </a>
          </div>
          <div class="gross-total price">
            {{ $n(item.grossTotal, "currency") }}
          </div>
          <v-btn
            icon
            aria-label="Rimuovi dal carrello"
            @click.prevent="remove"
            @mousedown.stop
            class="delete-icon"
            v-if="
              !(
                item.product.productInfos &&
                item.product.productInfos.USER_DISABLED == '1'
              )
            "
          >
            <v-icon>$delete</v-icon>
          </v-btn>
        </div>
      </v-list-item-action>
    </v-list-item>
    <v-divider></v-divider>
    <v-dialog v-model="removeDialog" max-width="400">
      <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div>
      <v-card>
        <v-card-text class="body-1 pt-8">
          {{
            $t("message.remove", {
              name: this.product.name,
              quantity: this.quantity,
              unit: this.unit
            })
          }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="removeDialog = false">
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click.prevent="doRemove"
            @mousedown.stop
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style global lang="scss">
.lead-time-wrapper,
.day-lock-wrapper,
.lock-cutoff-wrapper {
  .not-available-product {
    margin-top: 10px;
    margin-bottom: -10px;
  }
  .not-available-description {
    font-size: 12px !important;
  }
  margin-left: 90px;
  .is-checkout {
    margin-top: 19px;
    margin-bottom: -20px;
    margin-left: 21px;
  }
  .lead-time {
    display: inline;
  }
  .day-lock,
  .hour-lock {
    display: inline;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .cart-item-list-item {
    flex-wrap: wrap;
    justify-content: flex-end;

    .v-list-item__action {
      margin-left: 0 !important;
    }
    &::after {
      display: none !important;
    }
  }
  .cart-item-content {
    flex-grow: 1;
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .checkout .cart-item .cart-item-list-item .v-list-item__action {
    margin-left: 0 !important;
  }
}

@media (max-width: 360px) {
  .cart-item-list-item {
    padding-left: 0;
    padding-right: 0;
  }
  .cart-item {
    .gross-total.price {
      width: 50px !important;
      font-size: 16px !important;
    }
    .delete-icon {
      max-width: 30px;
    }
  }
}

.cart-item {
  .accept-alternative-icon {
    position: absolute;
    z-index: 1;
    margin-top: 35px;
    margin-left: 2px;
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice";
import ProductPromo from "@/components/product/ProductPromo";
import ProductLeadTime from "@/components/product/ProductLeadTime";
import ProductDayLock from "@/components/product/ProductDayLock";
import ProductLockCutoff from "@/components/product/ProductLockCutoff";
import ProductAxBCutoff from "@/components/product/ProductAxBCutoff";

import productMixin from "~/mixins/product";

import { mapGetters } from "vuex";
import get from "lodash/get";

export default {
  name: "CartItem",
  components: {
    ProductPrice,
    ProductPromo,
    ProductLeadTime,
    ProductDayLock,
    ProductLockCutoff,
    ProductAxBCutoff
  },
  props: ["item", "full", "itemIndex", "isCheckout", "acceptAlternatives"],
  mixins: [productMixin],
  data() {
    return { removeDialog: false };
  },
  computed: {
    ...mapGetters({
      getDeliveryServiceId: "cart/getDeliveryServiceId"
    }),
    product() {
      return this.item.product;
    },
    classCardContainerObject: function() {
      if (this.isCheckout && this.$vuetify.breakpoint.mdAndUp) {
        return "isCheckout d-flex flex-column justify-space-around";
      } else {
        if (!this.$vuetify.breakpoint.xs) {
          return "d-flex flex-row justify-space-around";
        }
        return "XS";
      }
    },
    deliveryServiceWithReplaceableItems() {
      return global.config.deliveryServiceWithAlternativesProduct.includes(
        this.getDeliveryServiceId
      );
    },
    isReplaceable() {
      return get(this.item, "product.productInfos.IS_REPLACEABLE") == "true";
    },
    acceptAlternative() {
      return (
        get(this.item, "cartItemInfo.accept_alternatives", "false") == "true"
      );
    }
  },
  methods: {
    remove() {
      this.removeDialog = true;
    },
    doRemove() {
      this.removeDialog = false;
      this.removeItem(this.item);
    }
  }
};
</script>
