var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cart-item"},[_c('div',{staticClass:"lead-time-wrapper"},[(_vm.item.product.productInfos.LEAD_TIME)?_c('ProductLeadTime',{attrs:{"time":_vm.item.product.productInfos.LEAD_TIME,"showTooltip":true,"isCheckout":_vm.isCheckout}}):_vm._e()],1),_c('div',{staticClass:"day-lock-wrapper"},[(_vm.item.product.productInfos.DAY_LOCK)?_c('ProductDayLock',{attrs:{"days":_vm.item.product.productInfos.DAY_LOCK,"showTooltip":true,"isCheckout":_vm.isCheckout}}):_vm._e()],1),_c('div',{staticClass:"lock-cutoff-wrapper"},[(_vm.item.product.productInfos.PRODUCT_LOCK_CUTOFF)?_c('ProductLockCutoff',{attrs:{"hours":_vm.item.product.productInfos.PRODUCT_LOCK_CUTOFF,"showTooltip":true,"isCheckout":_vm.isCheckout}}):_vm._e()],1),_c('div',{staticClass:"lock-cutoff-wrapper"},[(_vm.item.product.productInfos.PRODUCT_AXB_CUTOFF)?_c('ProductAxBCutoff',{attrs:{"hour":_vm.item.product.productInfos.PRODUCT_AXB_CUTOFF,"showTooltip":true,"isCheckout":_vm.isCheckout}}):_vm._e()],1),(_vm.deliveryServiceWithReplaceableItems && _vm.isReplaceable && _vm.isCheckout)?[_c('v-icon',{staticClass:"accept-alternative-icon",attrs:{"color":_vm.acceptAlternative ? 'primary' : '#ccc'}},[_vm._v(" $replace ")])]:_vm._e(),_c('v-list-item',{staticClass:"cart-item-list-item",class:{
      'ml-2':
        _vm.deliveryServiceWithReplaceableItems &&
        _vm.acceptAlternatives &&
        _vm.isCheckout
    },attrs:{"to":{
      name: 'Product',
      params: { slug: _vm.item.product.slug }
    }}},[_c('v-list-item-avatar',{attrs:{"width":_vm.full ? '80' : '60',"height":_vm.full ? '80' : '60',"rounded":"0"}},[_c('v-img',{attrs:{"src":_vm.item.product.mediaURL,"alt":'Immagine prodotto'}})],1),_c('v-list-item-content',{staticClass:"cart-item-content"},[(_vm.item.product.warehousePromo && _vm.full)?_c('ProductPromo',{attrs:{"warehousePromo":_vm.item.product.warehousePromo}}):_vm._e(),_c('v-list-item-subtitle',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.item.product.shortDescr)+" ")]),_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.item.product.name)+" ")]),_c('v-list-item-subtitle',[(
            _vm.item.product.productInfos.TIPOLOGIA == 'Pezzo' &&
              _vm.item.product.priceUmDisplay
          )?_c('span',{staticClass:"weight-unit"},[_vm._v(" "+_vm._s(_vm.$n(_vm.item.product.priceUmDisplay, "currency"))+"/"+_vm._s(_vm.item.product.weightUnitDisplay)+", ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.item.product.description)+" ")])],1),_c('v-list-item-action',[_c('div',{class:'actions d-flex align-center'},[(_vm.full)?_c('ProductPrice',{attrs:{"product":_vm.item.product,"showStandard":false}}):_vm._e(),(false)?_c('ProductPromo',{attrs:{"warehousePromo":_vm.item.product.warehousePromo}}):_vm._e(),(
            !(
              _vm.item.product.productInfos &&
              _vm.item.product.productInfos.USER_DISABLED == '1'
            )
          )?_c('div',{staticClass:"qty-wrap rounded-pill",class:{ 'not-empty': _vm.quantity > 0 }},[_c('a',{staticClass:"minus rounded-circle",attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.minus.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-icon',{attrs:{"aria-label":"Aggiungere meno quantità"}},[_vm._v("$minus")])],1),_c('div',{staticClass:"val-cnt"},[_c('span',{staticClass:"val"},[_vm._v(_vm._s(_vm.quantity)+" "+_vm._s(_vm.unit))]),_c('span',{staticClass:"small"},[_vm._v(_vm._s(_vm.quantityPerUnit))])]),_c('a',{staticClass:"plus rounded-circle",attrs:{"aria-label":"Aggiungi al tuo carrello","role":"button"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.plus.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[(_vm.quantity > 0)?_c('v-icon',{attrs:{"aria-label":"Aggiungere più quantità"}},[_vm._v("$plus")]):_c('v-icon',{attrs:{"aria-label":"Aggiungi al tuo carrello"}},[_vm._v("$cart")])],1)]):_vm._e(),_c('div',{staticClass:"gross-total price"},[_vm._v(" "+_vm._s(_vm.$n(_vm.item.grossTotal, "currency"))+" ")]),(
            !(
              _vm.item.product.productInfos &&
              _vm.item.product.productInfos.USER_DISABLED == '1'
            )
          )?_c('v-btn',{staticClass:"delete-icon",attrs:{"icon":"","aria-label":"Rimuovi dal carrello"},on:{"click":function($event){$event.preventDefault();return _vm.remove.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("$delete")])],1):_vm._e()],1)])],1),_c('v-divider'),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}},[_c('div',{staticClass:"vuedl-layout__closeBtn",on:{"click":function($event){_vm.removeDialog = false}}},[_vm._v(" × ")]),_c('v-card',[_c('v-card-text',{staticClass:"body-1 pt-8"},[_vm._v(" "+_vm._s(_vm.$t("message.remove", { name: this.product.name, quantity: this.quantity, unit: this.unit }))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.removeDialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){$event.preventDefault();return _vm.doRemove.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_vm._v(" Si ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }